@import 'styles/constants';

.ant-select {
  width: 100%;
  font-size: $input-font-size;
  font-weight: 400;
}

.ant-select-selection-placeholder {
  color: $color-darGray;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: $input-height - 2px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: $input-height;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: $input-height;
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: $input-height - 8px;
}
