@import 'styles/constants';
.ant-input,
.ant-input-number {
  font-size: 18px;
  line-height: $input-height - 10px;

  &::placeholder,
  input::placeholder {
    color: $color-darGray;
  }
}

.ant-input-number {
  font-size: 18px;
  width: 100%;
}

.ant-input-number-input {
  height: $input-height - 2px;
}
