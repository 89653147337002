$color-gray: #2d3748;
$color-darkPink: #0e3263;
$color-white: #f0f0f0;
$color-black: #333333;
$color-yellow: #fedb31;
$color-input: #4f4f4f;
$color-white2: #ececec;
$color-white3: #dee2e6;
$color-gray2: #f6f6f6;
$color-gray3: #bdbdbd;
$color-darGray: #828282;
$color-green: #64b65b;
$color-gray4: #c8c7cc;
$color-black1: #1f1f1f;
$main-blue: #002e6c;
$color-orange: #EB861E;

$input-height: 50px;
$input-font-size: 18px;
